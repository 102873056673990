import React, { useContext, useEffect, useState } from 'react'
import { UserContext } from '../../context/UserContext';
import { LineAds } from '../../components/other/Ads';
import NavigationBar from '../../components/client/NavigationBar';
import Footer from '../../components/client/Footer';
import { AddNewOrder } from '../../functions/client/Toogle/AddNewOrder'

import '../../styles/client/page/ConfirmOrder.css'
import { GetProductById } from '../../functions/client/GetProductList';

export default function ConfirmOrder() {
    const { userInformation } = useContext(UserContext);
    const [userCurrentBasket, setUserCurrentBasket] = useState([])
    let tempUserCurrentBasketIds = []

    useEffect(() => {
        userInformation?.basket?.forEach(itemID => {
            GetProductById({ itemID, jwt: userInformation.jwt })
                .then(response => {
                    if (!tempUserCurrentBasketIds.includes(response.id)) {
                        tempUserCurrentBasketIds.push(response.id)
                        setUserCurrentBasket(userCurrentBasket => [...userCurrentBasket, response])
                    }
                })
        });
    }, [userInformation])

    const [paymentMethod, setPaymentMethod] = useState('cash');
    const [location, setLocation] = useState('');
    const [pickupDate, setPickupDate] = useState('');
    const [availableDates, setAvailableDates] = useState([
        '2024-09-15', '2024-09-20', '2024-09-25', '2024-09-30',
    ]);
    const [availableTimes, setAvailableTimes] = useState([
        '10:00', '12:00', '14:00', '16:00',
    ]);

    const handlePaymentChange = (event) => {
        setPaymentMethod(event.target.value);
    };

    const handleLocationChange = (event) => {
        setLocation(event.target.value);
    };

    const handlePickupDateChange = (event) => {
        setPickupDate(event.target.value);
    };


    return (
        <>
            <LineAds />

            <NavigationBar />

            <div className="wide-checkout-container">
                <div className="checkout-content">

                    <div className="payment-selection">
                        <h3 className="section-title">Ödəniş Forması</h3>
                        <div className="payment-method">
                            <label className="radio-container">
                                <input
                                    type="radio"
                                    name="paymentMethod"
                                    value="cash"
                                    checked={paymentMethod === 'cash'}
                                    onChange={handlePaymentChange}
                                />
                                <span className="custom-radio"></span>
                                Nağd
                            </label>

                            <label className="radio-container">
                                <input
                                    type="radio"
                                    name="paymentMethod"
                                    value="cart"
                                    checked={paymentMethod === 'cart'}
                                    onChange={handlePaymentChange}
                                    disabled
                                />
                                <span className="custom-radio"></span>
                                Təhvil zamanı kartla ödəniş
                            </label>
                            <p className="warning">Kartla ödəniş yaxın zamanda aktiv olacaq!</p>

                            <label className="radio-container">
                                <input
                                    type="radio"
                                    name="paymentMethod"
                                    value="online"
                                    checked={paymentMethod === 'online'}
                                    onChange={handlePaymentChange}
                                    disabled
                                />
                                <span className="custom-radio"></span>
                                Online ödəniş
                            </label>
                            <p className="warning">Online ödəniş yaxın zamanda aktiv olacaq!</p>
                        </div>
                    </div>

                    <div className="location-selection">
                        <h3 className="section-title">Təhvil alma yeri</h3>
                        <select value={location} onChange={handleLocationChange}>
                            <option value="pudo-1">PUDO Tarqovu</option>
                        </select>
                    </div>

                    <div className="pickup-date-selection">
                        <h3 className="section-title">Tarix</h3>
                        <select value={pickupDate} onChange={handlePickupDateChange}>
                            {availableDates.map((date) => (
                                <option key={date} value={date}>{date}</option>
                            ))}
                        </select>

                        <h3 className="section-title">Saat</h3>
                        <select>
                            {availableTimes.map((time) => (
                                <option key={time} value={time}>{time}</option>
                            ))}
                        </select>
                    </div>

                    {/* <div className="order-summary">
                        <h3 className="section-title">Sifaris tutari</h3>
                        <p className="total-amount">Toplam: <strong>100 AZN</strong></p>
                    </div> */}

                    <button onClick={() => AddNewOrder({ userInformation, userCurrentBasket })} className="complete-order-btn">Siparişi Tamamla</button>
                </div>
            </div>

            <Footer />
        </>
    )
}
