import React, { useEffect, useState } from 'react'
import { getActiveCategoryList, HOST } from '../../server/api'
import ErrorAlert from '../../functions/other/ErrorAlert'
import { CreateNewProduct } from '../../functions/merchant/CreateNewProduct'
import { ToastContainer } from 'react-toastify'
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { storage } from '../../server/Firebase';

import merchant_product from '../../styles/merchant/screen/MerchantProductScreen.module.css'

export default function MerchantAddProductScreen({ userInformation }) {
    const [allActiveCategories, setAllActiveCategories] = useState([])
    const [images, setImages] = useState([])
    const [progressList, setProgressList] = useState([]);

    useEffect(() => {
        (async () => {
            const response = await fetch(HOST + getActiveCategoryList, {
                method: 'GET',
                headers: {
                    "Access-Control-Allow-Methods": "OPTIONS,POST,GET,PATCH",
                    "Access-Control-Allow-Headers": "Content-Type",
                    "Access-Control-Allow-Origin": "*",
                    'Content-Type': 'application/json'
                }
            });

            if (!response.ok) {
                ErrorAlert({ response: response })
            } else {
                setAllActiveCategories(await response.json())
            }
        })()
    }, [])

    const [currentProduct, setCurrentProduct] = useState({
        title: "",
        description: "",
        categoryId: "",
        price: 0,
        currency: "AZN",
        imgUrl: [],
        stock: 4,
        customizableItem: false,
        authorName: ""
    })

    const handleImageChange = (e) => {
        if (e.target.files) {
            const selectedImages = Array.from(e.target.files);
            setImages(selectedImages);
        }
    }

    const handleUpload = () => {
        if (images.length === 0) return;

        images.forEach((image, index) => {
            const storageRef = ref(storage, `images/${image.name}`);
            const uploadTask = uploadBytesResumable(storageRef, image);

            uploadTask.on(
                'state_changed',
                (snapshot) => {
                    const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                    setProgressList((prevProgressList) => {
                        const newProgressList = [...prevProgressList];
                        newProgressList[index] = progress;
                        return newProgressList;
                    });
                },
                (error) => {
                    console.log(error);
                },
                () => {
                    getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                        setCurrentProduct((prevProduct) => ({
                            ...prevProduct,
                            imgUrl: [...prevProduct.imgUrl, downloadURL],
                        }));
                    });
                }
            );
        });
    };

    return (
        <>
            <ToastContainer />
            <div className="form-container">
                <div>
                    <div className="form-group">
                        <label>Mehsul Adi:</label>
                        <input onChange={(e) => setCurrentProduct({ ...currentProduct, title: e.target.value })} type="text" id="product-name" name="product-name" required />
                    </div>

                    <div className="form-group">
                        <label>Hazirlayan sexs:</label>
                        <input onChange={(e) => setCurrentProduct({ ...currentProduct, authorName: e.target.value })} type="text" id="product-name" name="product-name" required />
                    </div>

                    <div className="form-group">
                        <label>Kategori:</label>
                        <select onChange={(e) => setCurrentProduct({ ...currentProduct, categoryId: e.target.value })} id="category" name="category" required>
                            {
                                allActiveCategories.map((cat, key) => (
                                    <option key={key} value={cat.id}>{cat.name}</option>
                                ))
                            }
                        </select>
                    </div>

                    <div className="form-group">
                        <label >Qiymeti:</label>
                        <input onChange={(e) => setCurrentProduct({ ...currentProduct, price: parseInt(e.target.value) })} type="number" id="price" name="price" step="0.01" required />
                    </div>

                    <div className="form-group">
                        <label >Aciqlama:</label>
                        <textarea onChange={(e) => setCurrentProduct({ ...currentProduct, description: e.target.value })} id="product-info" name="product-info" rows="4" required></textarea>
                    </div>

                    <div className="form-group">
                        <label >Sekiller:</label>
                        <input type="file" onChange={(e) => handleImageChange(e)} id="images" name="images[]" multiple />
                    </div>

                    <div>
                        <div style={{display: 'flex', marginBottom: 10}}>
                            {images.map((image, index) => (
                                <div className='image_loader'>
                                    <p>{progressList[index] ? progressList[index] : 0}%</p>
                                    {/* <img src='image_loader.png' /> */}
                                </div>
                            ))}
                        </div>

                        <button onClick={() => handleUpload()} type="submit">Yukle</button>
                    </div>

                    <div className="form-group">
                        <label>Stok Sayısı:</label>
                        <input onChange={(e) => setCurrentProduct({ ...currentProduct, stock: parseInt(e.target.value) })} type="number" id="stock" name="stock" required />
                    </div>

                    <div className="form-group checkbox-group">
                        <input onChange={(e) => setCurrentProduct({ ...currentProduct, customizableItem: e.target.value === 'on' ? true : false })} type="checkbox" id="special-order" name="special-order" />
                        <label>Ozel sifaris etmek olar</label>
                    </div>

                    <button onClick={() => CreateNewProduct({ userInformation, currentProduct })} type="submit">Elave et</button>
                </div>
            </div>
        </>
    )
}
