import { messages } from "../../Localization/Localization";
import { HOST, putRequestsAcceptProductApi } from "../../server/api";

export const ChangeProductStatus = async ({ p, jwt, ok }) => {
    try {
        const response = await fetch(HOST + putRequestsAcceptProductApi + `?requestId=${p?.id}&message=${messages[0]}`, {
            method: 'PUT',
            headers: {
                "Authorization": `Bearer ${jwt}`,
                "Access-Control-Allow-Methods": "OPTIONS,POST,GET,PATCH",
                "Access-Control-Allow-Headers": "Content-Type",
                "Access-Control-Allow-Origin": "*",
                'Content-Type': 'application/json'
            }
        });

        console.log(response);

    } catch (error) {
        console.error('Error ChangeProductStatus: ', error);
    }
}