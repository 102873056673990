import SwiperProductMenu from '../../components/client/SwiperProductMenu'
import PopularMerchants from '../../components/client/PopularMerchants'
import { LineAds, RandomSizeAds } from '../../components/other/Ads'
import { ProductContext } from '../../context/ProductContext'
import { UserContext } from '../../context/UserContext'
import Footer from '../../components/client/Footer'
import Header from '../../components/client/Header'
import React, { useContext } from 'react'

import '../../styles/client/page/Home.css'
import '../../styles/client/page/MerchantPage.css'
import { SvgMessage, SvgShare } from '../../assets/icons/Svgs'

export default function MerchantPage() {

  const { productList } = useContext(ProductContext);
  const { userInformation } = useContext(UserContext);

  // window.location.search.replace('?id=', '')
  

  return (
    <>
      <LineAds />

      <Header productList={productList} />

      <div class="profile-header">
        <img src="https://plus.unsplash.com/premium_photo-1723826751660-717811d0fbc6?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="Profile Picture" class="profile-picture" />
        <div class="profile-info">
          <h2 class="profile-name">Lucary</h2>
          <div class="popularity-rating">
            <span class="stars">★★★★☆</span>
          </div>
          <div className='profile-actions'>
            <div class="follower-count">867 takipci</div>
            <div class="follower-count">|</div>
            <div class="product-count">56 toplam satilmis mehsul</div>
          </div>
        </div>
        <div class="profile-actions">
          <button class="follow-btn">Takib Et</button>
          <button class="share-btn"><SvgShare/></button>
          <button onClick={() => window.location.href = 'lucary-direct'} class="contact-btn"><SvgMessage/></button>
        </div>
      </div>


      {
        productList?.map((p) => (
          <SwiperProductMenu p={p} />
        ))
      }

      <RandomSizeAds />

      {
        productList?.map((p) => (
          <PopularMerchants p={p} />
        ))
      }

      {!userInformation.isMobile && <Footer />}

    </>
  )
}
