import { createContext, useState, useEffect } from "react";
import { GetProductList } from "../functions/client/GetProductList";

export const ProductContext = createContext();

export const ProductProvider = ({ children }) => {
    const [productList, setProductList] = useState([]);
    const jwt = localStorage.getItem('lucary-token');

    const fetchProducts = async () => {

        if (jwt !== null) {
            GetProductList({ jwt })
                .then(response => response != undefined && setProductList(JSON.parse(response)))
        }
    };

    useEffect(() => {
        fetchProducts();
    }, [jwt]);

    return (
        <ProductContext.Provider value={{ productList }}>
            {children}
        </ProductContext.Provider>
    );
};
