import React from 'react'
import '../../styles/merchant/component/Header.css'
import { useNavigate } from 'react-router-dom'
import { SvgLogOut, SvgProfileLogin } from '../../assets/icons/Svgs'
import { LogOut } from '../../functions/client/LogOut'

export default function Header({ userInformation, path }) {
    return (
        <>
            <div className='merchant_header_container'>
                <h1>Lucary</h1>
                <div className='header_top_navi'>
                    <div onClick={() => LogOut()} className='login_btn'>
                        <SvgLogOut c="#000" />
                    </div>
                </div>
            </div>
        </>
    )
}
