import Cookies from 'js-cookie';
import { HOST, loginApi } from "../../server/api";
import ErrorAlert from "../other/ErrorAlert";
import { setDeviceId, setRefreshToken } from '../other/SetCookie';

export const LoginAsAdmin = async ({ email, psw, userInformation }) => {

  const body = {
    usernameOrEmailOrPhoneNumber: email,
    password: psw,
    deviceRequest: {
      deviceId: Cookies.get('deviceId') === undefined ? '6' : Cookies.get('deviceId'),
      appCodeName: userInformation?.appCodeName,
      appVersion: userInformation?.appVersion,
      cookieEnabled: userInformation?.cookieEnabled,
      language: userInformation?.language,
      userAgent: userInformation?.userAgent,
      vendor: userInformation?.vendor,
      platform: userInformation?.platform,
      deviceMemory: userInformation?.deviceMemory,
      hardwareConcurrency: userInformation?.hardwareConcurrency,
      height: userInformation?.height,
      width: userInformation?.width
    }
  }

  try {
    const response = await fetch(HOST + loginApi, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    });

    if (!response.ok) {
      ErrorAlert({ response: response })
    } else {

      const data = await response.json();

      if (data.role !== 'ADMIN') {
        ErrorAlert({ response: { code: '666' } })
      } else {
        setRefreshToken({refreshToken: data.refreshToken})
        setDeviceId({deviceId: data.deviceId})

        localStorage.setItem('lucary-token', data.jwt)
        localStorage.setItem('role', data.role)
        
        window.location.href = '/'
      }


    }

  } catch (error) {
    console.error('Error during login:', error);
  }
};