import { saveNewProductApi, HOST } from "../../server/api";
import ErrorAlert from "../other/ErrorAlert";

export async function CreateNewProduct({ userInformation, currentProduct }) {

    let body = {
        customizableItem: currentProduct.customizableItem,
        authorName: currentProduct.authorName,
        title: currentProduct.title,
        description: currentProduct.description,
        categoryId: currentProduct.categoryId,
        price: currentProduct.price,
        currency: currentProduct.currency,
        imgUrl: currentProduct.imgUrl,
        stock: currentProduct.stock
    }

    try {
        const response = await fetch(HOST + saveNewProductApi, {
            method: 'POST',
            headers: {
                "Authorization": `Bearer ${userInformation?.jwt}`,
                "Access-Control-Allow-Methods": "OPTIONS,POST,GET,PATCH",
                "Access-Control-Allow-Headers": "Content-Type",
                "Access-Control-Allow-Origin": "*",
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
        });


        if (!response.ok) {
            ErrorAlert({ response: response, jwt: userInformation?.jwt })
        } else {
            window.location.href = '/merchat-products'
        }

    } catch (error) {
        console.error('Error CreateNewProduct: ', error);
    }
}