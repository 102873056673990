import { addFavProductApi, HOST } from "../../../server/api";
import ErrorAlert from "../../other/ErrorAlert";

export const ToogleFavList = async ({ userInformation, singleProduct }) => {
    try {
        const response = await fetch(HOST + addFavProductApi + singleProduct.id, {
            method: 'PUT',
            headers: {
                "Authorization": `Bearer ${userInformation.jwt}`,
                "Access-Control-Allow-Methods": "OPTIONS,POST,GET,PATCH",
                "Access-Control-Allow-Headers": "Content-Type",
                "Access-Control-Allow-Origin": "*",
                'Content-Type': 'application/json'
            }
        });

        if (!response.ok) {
            ErrorAlert({ response: response })
        }

        return response.ok
    } catch (error) {
        console.error('Error AddCart: ', error);
    }
}