import { HOST, userInformation } from "../../server/api";
import ErrorAlert from "../other/ErrorAlert";
import { GetUserBasket } from "./Get/GetUserBasket";

export const GetUserInformation = async ({ jwt, setUserInformation, isMobile, defaultUserInformation }) => {
    try {
        const response = await fetch(HOST + userInformation, {
            method: 'GET',
            headers: {
                "Authorization": `Bearer ${jwt}`,
                "Access-Control-Allow-Methods": "OPTIONS,POST,GET,PATCH",
                "Access-Control-Allow-Headers": "Content-Type",
                "Access-Control-Allow-Origin": "*",
                'Content-Type': 'application/json'
            }
        });

        if (!response.ok) {
            ErrorAlert({ response: response, jwt: jwt })
        }

        let userInfo = await response.json()

        if (userInfo.role === 'GUEST') {
            GetUserBasket({ jwt }).then(response => setUserInformation({ ...defaultUserInformation, jwt: jwt, isMobile: isMobile, basket: response }))
        } else {
            GetUserBasket({ jwt }).then(response => setUserInformation({ ...userInfo, jwt: jwt, isMobile: isMobile, basket: response }))
        }
        
    } catch (error) {
        ErrorAlert({ response: { code: 'x066' } })
        console.log('Error x066 GetUserInformation: ', error);
    }
}