import { createBrowserRouter } from "react-router-dom";
import MerchantHomePage from './pages/merchant/MerchantHomePage.js'
import MerchantLoginPage from "./pages/merchant/MerchantLoginPage.js";
import MerchantRegisterPage from "./pages/merchant/MerchantRegisterPage.js";
import HomePage from "./pages/client/HomePage.js";
import RegisterPage from "./pages/client/RegisterPage.js";
import LoginPage from "./pages/client/LoginPage.js";
import SingleProductPage from "./pages/client/SingleProductPage.js";
import AdminHomePage from "./pages/admin/AdminHomePage.js";
import MerchantPage from "./pages/client/MerchantPage.js";
import BasketPage from "./pages/client/BasketPage.js";
import ProfilePage from "./pages/client/ProfilePage.js";
import ConfirmOrder from "./pages/client/ConfirmOrder.js";
import AdminLoginPage from "./pages/admin/AdminLoginPage.js";
import DirectBox from "./pages/other/DirectBox.js";

const subdomain = window.location.hostname.split('.')[0];

let routes;

if (subdomain === 'merchant') {
  routes = [
    {
      path: "/",
      element: <MerchantHomePage />,
    },
    {
      path: "/orders",
      element: <MerchantHomePage />,
    },
    {
      path: "/chate",
      element: <MerchantHomePage />,
    },
    {
      path: "/merchat-products",
      element: <MerchantHomePage />,
    },
    {
      path: "/merchat-add-product",
      element: <MerchantHomePage />,
    },
    {
      path: "/settings",
      element: <MerchantHomePage />,
    },
    {
      path: "/login",
      element: <MerchantLoginPage />,
    },
    {
      path: "/register",
      element: <MerchantRegisterPage />,
    }
  ];
} else if (subdomain === 'admin') {
  routes = [
    {
      path: "/",
      element: <AdminHomePage />,
    },
    {
      path: "/pending-merchants",
      element: <AdminHomePage />,
    },
    {
      path: "/all-merchants",
      element: <AdminHomePage />,
    },
    {
      path: "/all-categories",
      element: <AdminHomePage />,
    },
    {
      path: "/pending-products",
      element: <AdminHomePage />,
    },
    {
      path: "/login",
      element: <AdminLoginPage />,
    },
  ];
} else {
  routes = [
    {
      path: "/",
      element: <HomePage />,
    },
    {
      path: "/company-page",
      element: <MerchantPage />,
    },
    {
      path: "/confirm-order",
      element: <ConfirmOrder />,
    },
    {
      path: "/lucary-direct",
      element: <DirectBox />,
    },
    {
      path: "/profile",
      element: <ProfilePage />,
    },
    {
      path: "/basket",
      element: <BasketPage />,
    },
    {
      path: "/register",
      element: <RegisterPage />,
    },
    {
      path: "/login",
      element: <LoginPage />,
    },
    {
      path: "/product-detail",
      element: <SingleProductPage />,
    },
  ];
}

export const router = createBrowserRouter(routes);