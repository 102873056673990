import React, { useContext, useState } from 'react'
import { UserContext } from '../../../context/UserContext';

export default function UserInformationForm() {

    const { userInformation } = useContext(UserContext);

    const formatDate = (dateString) => {
        return dateString ? dateString.split('T')[0] : '';
    };


    const [formData, setFormData] = useState({
        userName: userInformation?.username || '',
        firstName: userInformation?.name || '',
        lastName: userInformation?.surname || '',
        email: userInformation?.email || '',
        phone: userInformation?.phone || '',
        birthDate: formatDate(userInformation?.dateOfBirth) || '',
        currentPassword: '',
        newPassword: '',
        confirmPassword: ''
    });

    const [originalData, setOriginalData] = useState({ ...formData });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const changedData = Object.keys(formData).reduce((acc, key) => {
            if (formData[key] !== originalData[key]) {
                acc[key] = formData[key];
            }
            return acc;
        }, {});

        console.log(changedData);

    };


    return (
        <form className="user-form" onSubmit={handleSubmit}>
            <div className="form-group">
                <label htmlFor="userName">Istifadeci Adiniz <span style={{ color: 'red' }}>{formData.userName.includes('user-') && '(Deyisdirilmelidir)'}</span></label>
                <input
                    type="text"
                    id="userName"
                    name="userName"
                    value={formData.userName}
                    onChange={handleInputChange}

                />
            </div>

            <div className="form-group">
                <label htmlFor="firstName">Adiniz</label>
                <input
                    type="text"
                    id="firstName"
                    name="firstName"
                    value={formData.firstName}
                    onChange={handleInputChange}

                />
            </div>

            <div className="form-group">
                <label htmlFor="lastName">Soyadiniz</label>
                <input
                    type="text"
                    id="lastName"
                    name="lastName"
                    value={formData.lastName}
                    onChange={handleInputChange}

                />
            </div>

            <div className="form-group">
                <label htmlFor="email">Email</label>
                <input
                    type="email"
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}

                />
            </div>

            <div className="form-group">
                <label htmlFor="phone">Mobile</label>
                <div className="phone-group">
                    <select
                        id="countryCode"
                        name="countryCode"
                        value={formData.countryCode}
                        onChange={handleInputChange}

                    >
                        <option value="+994">+994 (Aze)</option>
                    </select>
                    <input
                        type="tel"
                        id="phone"
                        name="phone"
                        value={formData.phone}
                        placeholder="xxx-xx-xx"
                        onChange={handleInputChange}

                    />
                </div>
            </div>

            <div className="form-group">
                <label htmlFor="birthDate">Date of Birth</label>
                <input
                    type="date"
                    id="birthDate"
                    name="birthDate"
                    value={formData.birthDate}
                    onChange={handleInputChange}

                />
            </div>

            <div className="form-group-seperator"></div>

            <div className="form-group">
                <label htmlFor="currentPassword">Current Password</label>
                <input
                    type="password"
                    id="currentPassword"
                    name="currentPassword"
                    value={formData.currentPassword}
                    onChange={handleInputChange}

                />
            </div>

            <div className="form-group">
                <label htmlFor="newPassword">New Password</label>
                <input
                    type="password"
                    id="newPassword"
                    name="newPassword"
                    value={formData.newPassword}
                    onChange={handleInputChange}

                />
            </div>

            <div className="form-group">
                <label htmlFor="confirmPassword">Confirm New Password</label>
                <input
                    type="password"
                    id="confirmPassword"
                    name="confirmPassword"
                    value={formData.confirmPassword}
                    onChange={handleInputChange}

                />
            </div>

            <div className="form-group">
                <button type="submit">Yenile</button>
            </div>
        </form>
    )
}
