import React from 'react'
import '../../styles/client/page/DirectBox.css'
import NavigationBar from '../../components/client/NavigationBar'
import { SvgMessage } from '../../assets/icons/Svgs'

export default function DirectBox() {
    return (
        <>
            <NavigationBar />

            <div className="intercom-container">
                <div className="sidebar">
                    <input type="text" placeholder="Axtar .." />

                    <div className="groups-section">
                        <ul>
                            <li className="group-item">
                                <img src="https://images.unsplash.com/photo-1724433530860-f094e39b64e7?q=80&w=1900&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="User 1" className="profile-pic" />
                                <h3>#DIY</h3>
                            </li>
                        </ul>
                    </div>

                    <div className="users-section">
                        <ul className="user-list">
                            <li className="user-item">
                                <img src="https://images.unsplash.com/photo-1724433530860-f094e39b64e7?q=80&w=1900&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="User 1" className="profile-pic" />
                                <div className="user-details">
                                    <p className="user-name">Lucary</p>
                                    <p className="last-message">Last message here</p>
                                </div>
                                <span className="message-date">12:30 PM</span>
                            </li>
                            <li className="user-item">
                                <img src="https://images.unsplash.com/photo-1724433530860-f094e39b64e7?q=80&w=1900&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="User 1" className="profile-pic" />
                                <div className="user-details">
                                    <p className="user-name">vsleov</p>
                                    <p className="last-message">Last message here</p>
                                </div>
                                <span className="message-date">12:30 PM</span>
                            </li>
                            <li className="user-item">
                                <img src="https://images.unsplash.com/photo-1724433530860-f094e39b64e7?q=80&w=1900&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="User 1" className="profile-pic" />
                                <div className="user-details">
                                    <p className="user-name">Ra3ul</p>
                                    <p className="last-message">Last message here</p>
                                </div>
                                <span className="message-date">12:30 PM</span>
                            </li>
                        </ul>
                    </div>
                </div>

                <div className="message-area">
                    <div className="messages">
                        <div className="message received">
                            <p>User 1: Hello!</p>
                        </div>
                        <div className="message sent">
                            <p>You: Hi there!</p>
                        </div>
                    </div>
                    <div className="message-input">
                        <input type="text" placeholder="Type your message..." />
                        <button>
                            <SvgMessage />
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}
