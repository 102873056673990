import React, { useContext, useState } from 'react'
import '../../styles/client/screen/RegisterAndLoginScreen.css'
import { SvgEyeClose, SvgEyeOpen } from '../../assets/icons/Svgs';
import NavigationBar from '../../components/client/NavigationBar';
import { LoginAsUser } from '../../functions/client/LoginAsUser';
import { merchant } from '../../server/api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { UserContext } from '../../context/UserContext.js';

export default function LoginPage() {

  let [email, setEmail] = useState('');
  let [psw, setPsw] = useState('');
  let [visiblePsw, setVisiblePsw] = useState(false);
  const { userInformation } = useContext(UserContext);

  return (
    <>
      <NavigationBar hideSearch={true} />
      
      <ToastContainer/>

      <div className='registerAndLoginScreenForm'>

        <div className='registration_form'>
          <div className='reg_sec_visible'>
            <p>İstifadəçi adı və ya E-mail daxil edin</p>
            <input onChange={(e) => setEmail(e.target.value)} id='email' value={email} type='email' />
          </div>

          <div className='reg_sec_visible'>
            <p>Şifrə</p>
            <div className='input_end_icon' onClick={() => setVisiblePsw(!visiblePsw)}>
              {
                !visiblePsw ? <SvgEyeClose /> : <SvgEyeOpen />
              }
            </div>
            <input onChange={(e) => setPsw(e.target.value)} id='psw' value={psw} type={visiblePsw ? 'text' : 'password'} />
          </div>

          <div className='__continue' onClick={() => LoginAsUser({ email, psw, userInformation, toast })}>
            <h3>Daxil Ol</h3>
          </div>

          {/* <div className='continue__as__social'>
            <div onClick={() => LoginAsSocial({ platform: 'Google' })}>
              <SvgGoogle />
            </div>
            <div onClick={() => LoginAsSocial({ platform: 'Apple' })}>
              <SvgApple />
            </div>
          </div> */}

          <div style={{ display: 'flex' }}>
            <p style={{ marginLeft: 8, marginRight: 8, fontSize: 12, marginTop: 18, fontStyle: 'italic' }}>Kömək lazımdır?</p>
            <p onClick={() => window.location.href = merchant} style={{ marginLeft: 8, marginRight: 8, fontSize: 12, marginTop: 18, fontStyle: 'italic', cursor: 'pointer' }}>Partnyor Ol</p>
          </div>

          <div className='__already'>
            <p>Hesabınız yoxdur?</p>
            <h3 onClick={() => window.location.href = '/register'}>Qeydiyyatdan Keçin</h3>
          </div>
        </div>

      </div>

    </>
  )
}
