import UserInformationForm from '../../components/client/Profile/UserInformationForm';
import UserOrderHistory from '../../components/client/Profile/UserOrderHistory';
import NavigationBar from '../../components/client/NavigationBar';
import LeftBar from '../../components/client/Profile/LeftBar';
import { LineAds } from '../../components/other/Ads';
import Footer from '../../components/client/Footer';
import React from 'react';

import '../../styles/client/page/ProfilePage.css';

export default function ProfilePage() {
    return (
        <>
            <LineAds />
            <NavigationBar />

            <div className='profile_container'>
                <LeftBar />

                <div className="form-container">
                    {window.location.search.includes('user-information') && <UserInformationForm />}
                    {window.location.search.includes('user-order-history') && <UserOrderHistory />}
                </div>
            </div>
            <Footer />
        </>
    );
}
