import React from 'react'
import { SvgCategories } from '../../assets/icons/Svgs'

export default function LeftBar() {
    return (
        <div className='user_profile_left_bar'>
            <div>
                <div onClick={() => window.location.href = '/pending-products'} className={window.location.href.includes('pending-products') ? '__active' : ''}>
                    <SvgCategories />
                    <p>Yeni Mehsul Sorgulari</p>
                </div>
                <div onClick={() => window.location.href = '/pending-merchants'} className={window.location.href.includes('pending-merchants') ? '__active' : ''}>
                    <SvgCategories />
                    <p>Yeni Merchant Sorgulari</p>
                </div>
                <div onClick={() => window.location.href = '/all-merchants'} className={window.location.href.includes('all-merchants') ? '__active' : ''}>
                    <SvgCategories />
                    <p>Merchantlar</p>
                </div>
            </div>
                
            <div>
                <div onClick={() => window.location.href = '/all-products'} className={window.location.href.includes('all-products') ? '__active' : ''}>
                    <SvgCategories />
                    <p>Mehsullar</p>
                </div>
                <div onClick={() => window.location.href = '/all-categories'} className={window.location.href.includes('all-categories') ? '__active' : ''}>
                    <SvgCategories />
                    <p>Kategoriyalar</p>
                </div>
            </div>

            <div>
                <div>
                    <SvgCategories />
                    <p>Mesajlar</p>
                </div>
                <div>
                    <SvgCategories />
                    <p>Suallar</p>
                </div>
                <div>
                    <SvgCategories />
                    <p>Musteriler</p>
                </div>
            </div>
        </div>
    )
}
