import { getMerchantProductsApi, HOST } from '../../server/api'
import ErrorAlert from '../../functions/other/ErrorAlert'
import React, { useEffect, useState } from 'react'
import { ToastContainer } from 'react-toastify'

import merchant_product from '../../styles/merchant/screen/MerchantProductScreen.module.css'

export default function MerchantProductScreen({ userInformation }) {

    const [productList, setProductList] = useState([])

    useEffect(() => {
        (async () => {
            try {
                const response = await fetch(HOST + getMerchantProductsApi, {
                    method: 'GET',
                    headers: {
                        "Authorization": `Bearer ${userInformation?.jwt}`,
                        "Access-Control-Allow-Methods": "OPTIONS,POST,GET,PATCH",
                        "Access-Control-Allow-Headers": "Content-Type",
                        "Access-Control-Allow-Origin": "*",
                        'Content-Type': 'application/json'
                    }
                })

                if (!response.ok) {
                    ErrorAlert({ response: response })
                }

                let data = await response.json()
                setProductList(data)
            } catch (error) {
                console.log('Error MerchantProductScreen: ', error);
            }
        })()
    }, [userInformation?.jwt])

    return (
        <>
            <ToastContainer />

            <div className={merchant_product.product_screen_container}>
                <div className={merchant_product.filter_container}>
                    <div className={merchant_product.filter_options}>
                        <select id="category" name="category">
                            <option value="elektronik">Elektronik</option>
                            <option value="kitap">Kitap</option>
                            <option value="moda">Moda</option>
                        </select>

                        <select id="stock" name="stock">
                            <option value="in-stock">Stokta Var</option>
                            <option value="out-stock">Stokta Yok</option>
                        </select>

                        <input type="number" id="min-price" placeholder="Min Fiyat" min="0" />
                        <input type="number" id="max-price" placeholder="Max Fiyat" min="0" />
                    </div>
                </div>

                <div className={merchant_product.product_list}>
                    {
                        productList?.map(cat => (
                            cat?.products?.map(product => (
                                <div className={merchant_product.product_card} >
                                    <img src={product.imgUrl[0]} alt={product.title} />
                                    <div className={merchant_product.product_info}>
                                        <h3>{product.title}</h3>
                                        <p>{product.categoryName}</p>
                                        <p>Qiymeti: {product.price}</p>
                                        <p>Stok: {product.stock} eded</p>
                                        <button className={merchant_product.detail_btn}>Detaylar</button>
                                    </div>
                                </div>
                            ))
                        ))
                    }
                </div>
            </div >
        </>
    )
}
