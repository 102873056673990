import { ToogleCart } from "./ToogleCart";

export const ChaneCountAtBasket = async (type, item, setUserCurrentBasket, userCurrentBasket, userInformation, setUserInformation) => {
    let newCount = []

    userCurrentBasket.forEach((element, key) => {

        switch (type) {
            case 'plus':
                if (element.id === item.id) {
                    newCount.push({ ...element, count: element.count !== undefined ? item.count + 1 : 2 })
                } else {
                    newCount.push({ ...element })
                }

                if (userCurrentBasket.length - 1 === key) {
                    setUserCurrentBasket(newCount)
                }
                break;
            case 'minus':
                if (element.count === 1) {
                    setUserCurrentBasket(newCount)
                    ToogleCart({inBasket: true, userInformation: userInformation, singleProduct: item, setUserInformation: setUserInformation}) 
                } else {
                    if (element.id === item.id) {
                        newCount.push({ ...element, count: element.count !== undefined ? item.count - 1 : 2 })
                    } else {
                        newCount.push({ ...element })
                    }
                }


                if (userCurrentBasket.length - 1 === key) {
                    setUserCurrentBasket(newCount)
                }
                break;
            case 'remove':
                if (element.id !== item.id) {
                    newCount.push({ ...element })
                }

                if (userCurrentBasket.length - 1 === key) {
                    setUserCurrentBasket(newCount)
                    ToogleCart({inBasket: true, userInformation: userInformation, singleProduct: item, setUserInformation: setUserInformation}) 
                }
                break;

            default:
                break;
        }

    });
}