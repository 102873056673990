import React, { useContext, useState } from 'react'
import '../../styles/client/screen/RegisterAndLoginScreen.css'
import { SvgEyeClose, SvgEyeOpen } from '../../assets/icons/Svgs.js';
import NavigationBar from '../../components/client/NavigationBar.js';
import { LoginAsUser } from '../../functions/client/LoginAsUser.js';
import { merchant } from '../../server/api.js';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { UserContext } from '../../context/UserContext.js';
import { LoginAsAdmin } from '../../functions/admin/LoginAsAdmin.js';

export default function AdminLoginPage() {

  let [email, setEmail] = useState('');
  let [psw, setPsw] = useState('');
  let [visiblePsw, setVisiblePsw] = useState(false);
  const { userInformation } = useContext(UserContext);

  return (
    <>
      <NavigationBar hideSearch={true} />
      
      <ToastContainer/>

      <div className='registerAndLoginScreenForm'>

        <div className='registration_form'>
          <div className='reg_sec_visible'>
            <p>İstifadəçi adı və ya E-mail daxil edin</p>
            <input onChange={(e) => setEmail(e.target.value)} id='email' value={email} type='email' />
          </div>

          <div className='reg_sec_visible'>
            <p>Şifrə</p>
            <div className='input_end_icon' onClick={() => setVisiblePsw(!visiblePsw)}>
              {
                !visiblePsw ? <SvgEyeClose /> : <SvgEyeOpen />
              }
            </div>
            <input onChange={(e) => setPsw(e.target.value)} id='psw' value={psw} type={visiblePsw ? 'text' : 'password'} />
          </div>

          <div className='__continue' onClick={() => LoginAsAdmin({ email, psw, userInformation, toast })}>
            <h3>Daxil Ol</h3>
          </div>

          {/* <div className='continue__as__social'>
            <div onClick={() => LoginAsSocial({ platform: 'Google' })}>
              <SvgGoogle />
            </div>
            <div onClick={() => LoginAsSocial({ platform: 'Apple' })}>
              <SvgApple />
            </div>
          </div> */}
        </div>

      </div>

    </>
  )
}
