import React from 'react'
import '../../styles/component/primary/Footer.css'

export default function Footer({ userInformation }) {
    return (
        <div className='footer_cover'>
            <div className='footer_container'>
                <div className='footer_sections'>
                    <div className='footer_section'>
                        <h2>Lucary</h2>
                        <h3>Biz Kimik</h3>
                        <h3>Partnyor Ol</h3>
                        <h3>Sosial Media</h3>
                        <h3>Karyera</h3>
                        <h3>Əlaqə</h3>
                    </div>

                    <div className='footer_section'>
                        <h2>Aksiyalar</h2>
                        <h3>Premenium Hesabı</h3>
                        <h3>Hədiyyə Fikirləri</h3>
                        <h3>Lucary fürsətləri</h3>
                    </div>

                    <div className='footer_section'>
                        <h2>Dəstək</h2>
                        <h3>Tez-tez Verilen Suallar</h3>
                        <h3>Canli Dəstək</h3>
                    </div>
                </div>

            </div>
            <div className='footer_copyright'>
                <p>©2024 Lucary MMC</p>
            </div>
        </div>
    )
}
