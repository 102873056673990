import axios from "axios";
import { HOST, registerGuestUserApi } from "../../server/api";
import ErrorAlert from "../other/ErrorAlert";

export const CreateUserAsGuest = async () => {
    try {

        const response = await fetch(HOST + registerGuestUserApi, {
            method: 'POST',
            headers: {
                "Access-Control-Allow-Methods": "OPTIONS,POST,GET,PATCH",
                "Access-Control-Allow-Headers": "Content-Type",
                "Access-Control-Allow-Origin": "*",
                'Content-Type': 'application/json'
            }
        });

        if (!response.ok) {
            ErrorAlert({ response: response })
        }

        let result = await response.json()
        
        return result
    } catch (error) {
        console.error('Error GetProductList: ', error);
    }
}