import React from 'react'
import admin_home_page from '../../styles/admin/AdminHomePage.module.css'
import { ChangeProductStatus } from '../../functions/admin/ChangeProductStatus'

export default function NewProducts({requestsProductList, userInformation}) {
    return (
        <div className={admin_home_page.pending_list}>
            {
                requestsProductList?.map((p) => (
                    <div className={admin_home_page.product_card}>
                        <img src={p?.imgUrl[0]} alt="Product Image" className={admin_home_page.product_image} />
                        <div className={admin_home_page.product_details}>
                            <h2 className={admin_home_page.product_name}>{p?.title}</h2>
                            <p className={admin_home_page.product_stock}>Stock: <span>{p?.stock}</span></p>
                            {
                                p?.customizableItem &&
                                <div className={admin_home_page.custom_order}>
                                    <p>Ozel sifaris edile biler</p>
                                </div>
                            }
                            <p className={admin_home_page.product_description}>{p?.description}</p>
                            <p className={admin_home_page.product_date}>Shared on: <span>{p?.requestsProductList}</span></p>
                            <p className={admin_home_page.product_author}>Author: <span>{p?.authorName}</span></p>
                            <p className={admin_home_page.product_category}>Category: <span>{p?.categoryName}</span></p>
                        </div>
                        <div className={admin_home_page.actions}>
                            <button onClick={() => ChangeProductStatus({ p: p, jwt: userInformation.jwt, ok: true })} style={{ width: 120, height: 120, margin: 8 }} className={admin_home_page.approve_btn}>Approve</button>
                            <button className={admin_home_page.reject_btn}>Reject</button>
                        </div>
                    </div>
                ))
            }
        </div>
    )
}
