import { checkEmailApi, HOST } from "../../server/api";

export const CheckEmail = async ({email, setEmailCheckLoader, setIsEmailValid, isEmailValid}) => {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (emailPattern.test(email)) {
        if (!isEmailValid) {
            setEmailCheckLoader(true)
            try {
                const response = await fetch(HOST + checkEmailApi + email, {
                    method: 'GET',
                    headers: {
                        "Access-Control-Allow-Methods": "OPTIONS,POST,GET,PATCH",
                        "Access-Control-Allow-Headers": "Content-Type",
                        "Access-Control-Allow-Origin": "*",
                        'Content-Type': 'application/json'
                    }
                });
                const data = await response.json();
    
                setEmailCheckLoader(false)
                setIsEmailValid(!data)
            } catch (error) {
                console.error('Error check email:', error);
            }
        }
    }
}