import React, { useContext, useEffect, useState } from 'react'
import '../../styles/merchant/screen/RegisterAndLoginScreen.css'
import { SvgEyeClose, SvgEyeOpen } from '../../assets/icons/Svgs';
import NavigationBar from '../../components/client/NavigationBar';
import { LoginAsMerchant } from '../../functions/merchant/LoginAsMerchant';
import { ToastContainer } from 'react-toastify';

export default function MerchantLoginPage() {

  let [email, setEmail] = useState('');
  let [psw, setPsw] = useState('');
  let [visiblePsw, setVisiblePsw] = useState(false);

  useEffect(() => {
    if (window.localStorage.getItem('role') === 'MERCHANT') {
      window.location.href = '/'
    }
  }, [])


  return (
    <>
      <NavigationBar hideSearch={true} />
      <ToastContainer />

      <div className='registerAndLoginScreenForm'>

        <div className='registration_form'>
          <div className='reg_sec_visible'>
            <p>İstifadəçi adı və ya E-mail daxil edin</p>
            <input onChange={(e) => setEmail(e.target.value)} id='email' value={email} type='email' />
          </div>

          <div className='reg_sec_visible'>
            <p>Şifrə</p>
            <div className='input_end_icon' onClick={() => setVisiblePsw(!visiblePsw)}>
              {
                !visiblePsw ? <SvgEyeClose /> : <SvgEyeOpen />
              }
            </div>
            <input onChange={(e) => setPsw(e.target.value)} id='psw' value={psw} type={visiblePsw ? 'text' : 'password'} />
          </div>

          <div className='__continue' onClick={() => LoginAsMerchant({ email, psw })}>
            <h3>Daxil Ol</h3>
          </div>

          <div style={{ display: 'flex' }}>
            <p style={{ marginLeft: 8, marginRight: 8, fontSize: 12, marginTop: 18, fontStyle: 'italic' }}>Kömək lazımdır?</p>
          </div>

          <div className='__already'>
            <p>Hesabınız yoxdur?</p>
            <h3 onClick={() => window.location.href = '/register'}>Qeydiyyatdan Keçin</h3>
          </div>
        </div>

      </div>

    </>
  )
}
