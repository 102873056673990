import React, { useContext, useEffect, useState } from 'react'
import { GetPendingProductList } from '../../functions/admin/GetAllProductList';
import { ChangeProductStatus } from '../../functions/admin/ChangeProductStatus.js';
import { UserContext } from '../../context/UserContext.js';
import admin_home_page from '../../styles/admin/AdminHomePage.module.css'
import LeftBar from '../../components/admin/LeftBar.js';
import NewMerchants from '../../screens/admin/NewMerchants.js';
import NewProducts from '../../screens/admin/NewProducts.js';
import NewCategories from '../../screens/admin/NewCategories.js';
import MerchantControl from '../../screens/admin/MerchantControl.js';

export default function AdminHomePage() {

  let [requestsProductList, setRequestsProductList] = useState([])
  const { userInformation } = useContext(UserContext);

  useEffect(() => {

    if (userInformation?.role !== 'ADMIN') {
      window.location.href = 'login'
    } else {
      GetPendingProductList({ jwt: userInformation?.jwt })
        .then(response => {
          response != undefined && setRequestsProductList(JSON.parse(response))
        })
    }

  }, [])

  return (
    <>
      <div className={admin_home_page.admin_dashboard_container}>
        <LeftBar />

        {window.location.pathname === '/pending-merchants' && <NewMerchants userInformation={userInformation} />}
        {window.location.pathname === '/pending-products' && <NewProducts requestsProductList={requestsProductList} userInformation={userInformation} />}
        {window.location.pathname === '/all-categories' && <NewCategories userInformation={userInformation} />}
        {window.location.pathname === '/all-merchants' && <MerchantControl userInformation={userInformation} />}
      </div>
    </>
  )
}
