import { HOST, registerUserApi } from "../../server/api";
import ErrorAlert from "../other/ErrorAlert";
import { LoginAsUser } from "./LoginAsUser";

export const RegisterAsUser = async ({ email, OTPCode, OTPID, psw, birthDay, userInformation, setAlertPopUp }) => {

  let body = {
    device: {
      appCodeName: userInformation.appCodeName !== undefined ? userInformation.appCodeName : "",
      appVersion: userInformation.appVersion !== undefined ? userInformation.appVersion : "",
      cookieEnabled: userInformation.cookieEnabled !== undefined ? userInformation.cookieEnabled : "",
      deviceMemory: userInformation.deviceMemory !== undefined ? userInformation.deviceMemory : "",
      language: userInformation.language !== undefined ? userInformation.language : "",
      platform: userInformation.platform !== undefined ? userInformation.platform : "",
      userAgent: userInformation.userAgent !== undefined ? userInformation.userAgent : "",
      vendor: userInformation.vendor !== undefined ? userInformation.vendor : "",
      width: toString(window.screen.width),
      height: toString(window.screen.height),
    },
    dateOfBirth: birthDay,
    email: email,
    otp: OTPCode,
    verificationId: OTPID,
    password: psw,
  }

  try {
    const response = await fetch(HOST + registerUserApi, {
      method: 'POST',
      headers: {
        "Access-Control-Allow-Methods": "OPTIONS,POST,GET,PATCH",
        "Access-Control-Allow-Headers": "Content-Type",
        "Access-Control-Allow-Origin": "*",
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body),
    });

    if (response.ok) {
      LoginAsUser({ email, psw })
    } else {
      ErrorAlert({ status: response.status, statusText: response.statusText, response: response, setAlertPopUp: setAlertPopUp })
    }

  } catch (error) {
    console.error('Error user register:', error);
  }
}