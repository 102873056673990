import React from 'react'
import { SvgCategories } from '../../../assets/icons/Svgs'

export default function LeftBar() {
    return (
        <div className='user_profile_left_bar'>
            <div>
                <div onClick={() => window.location.href = '/profile?user-order-history'} className={window.location.search.includes('user-order-history') ? '__active' : ''}>
                    <SvgCategories />
                    <p>Sifarislerim</p>
                </div>
                <div onClick={() => window.location.href = '/profile?user-favs'} className={window.location.search.includes('user-favs') ? '__active' : ''}>
                    <SvgCategories />
                    <p>Favorilerim</p>
                </div>
                <div>
                    <SvgCategories />
                    <p>Deyerlendirmelerim</p>
                </div>
                <div>
                    <SvgCategories />
                    <p>Suallarim</p>
                </div>
            </div>

            <div>
                <div>
                    <SvgCategories />
                    <p>Endirim kuponlarim</p>
                </div>
                <div>
                    <SvgCategories />
                    <p>Daha Evvel baxdiqlarim</p>
                </div>
                <div>
                    <SvgCategories />
                    <p>Takip etdiyin magazalar</p>
                </div>
                <div>
                    <SvgCategories />
                    <p>Trend mehsullar</p>
                </div>
            </div>

            <div>
                <div>
                    <SvgCategories />
                    <p>Lucary eventler</p>
                </div>
                <div>
                    <SvgCategories />
                    <p>Cekilisler</p>
                </div>
                <div>
                    <SvgCategories />
                    <p>Yarismalar</p>
                </div>
            </div>

            <div>
                <div onClick={() => window.location.href = '/profile?user-information'} className={window.location.search.includes('user-information') ? '__active' : ''}>
                    <SvgCategories />
                    <p>Istifadeci melumatlarim</p>
                </div>
                <div>
                    <SvgCategories />
                    <p>Address melumatlari</p>
                </div>
                <div>
                    <SvgCategories />
                    <p>Elave edilmis kartlar</p>
                </div>
                <div>
                    <SvgCategories />
                    <p>Bildiris secimleri</p>
                </div>
                <div>
                    <SvgCategories />
                    <p>Destek</p>
                </div>
            </div>
        </div>
    )
}
