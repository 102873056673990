import { HOST, userBasket } from "../../../server/api";
import ErrorAlert from "../../other/ErrorAlert";

export const GetUserBasket = async ({ jwt }) => {
    try {
        const response = await fetch(HOST + userBasket, {
            method: 'GET',
            headers: {
                "Authorization": `Bearer ${jwt}`,
                "Access-Control-Allow-Methods": "OPTIONS,POST,GET,PATCH",
                "Access-Control-Allow-Headers": "Content-Type",
                "Access-Control-Allow-Origin": "*",
                'Content-Type': 'application/json'
            }
        });

        if (!response.ok) {
            ErrorAlert({ response: response })
            return []
        } else {
            return await response.json()
        }


    } catch (error) {
        return []
    }
}