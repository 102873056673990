import { getAllRequestsProductApi, getPendingRequestsProductApi, HOSTPRODUCT } from "../../server/api";
import ErrorAlert from "../other/ErrorAlert";

export const GetAllProductList = async ({ jwt }) => {
    try {
        const response = await fetch(HOSTPRODUCT + getAllRequestsProductApi, {
            method: 'GET',
            headers: {
                "Authorization": `Bearer ${jwt}`,
                "Access-Control-Allow-Methods": "OPTIONS,POST,GET,PATCH",
                "Access-Control-Allow-Headers": "Content-Type",
                "Access-Control-Allow-Origin": "*",
                'Content-Type': 'application/json'
            }
        });

        if (!response.ok) {
            console.log(response);
            ErrorAlert({response: response, jwt: jwt})
        }

        let data = await response.text()
        return data
    } catch (error) {
        console.error('Error GetAllProductList: ', error);
    }
}

export const GetPendingProductList = async ({ jwt }) => {
    try {
        const response = await fetch(HOSTPRODUCT + getPendingRequestsProductApi, {
            method: 'GET',
            headers: {
                "Authorization": `Bearer ${jwt}`,
                "Access-Control-Allow-Methods": "OPTIONS,POST,GET,PATCH",
                "Access-Control-Allow-Headers": "Content-Type",
                "Access-Control-Allow-Origin": "*",
                'Content-Type': 'application/json'
            }
        });

        if (!response.ok) {
            console.log(response);
            ErrorAlert({response: response, jwt: jwt})
        }

        let data = await response.text()
        return data
    } catch (error) {
        console.error('Error GetPendingProductList: ', error);
    }
}