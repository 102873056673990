import { addNewOrderApi, HOST } from "../../../server/api";
import ErrorAlert from "../../other/ErrorAlert";

export const AddNewOrder = async ({ userInformation, userCurrentBasket }) => {

    console.log(userCurrentBasket);
    

    let newOrderList = []
    userCurrentBasket.forEach(async (item, key) => {
        
        newOrderList.push({
            saleId: item.id,
            number: 1,
            address: "666",
            saleType: "PRODUCT"
        })

        if (userCurrentBasket.length - 1 === key) {
            try {
                const response = await fetch(HOST + addNewOrderApi, {
                    method: 'POST',
                    headers: {
                        "Authorization": `Bearer ${userInformation.jwt}`,
                        "Access-Control-Allow-Methods": "OPTIONS,POST,GET,PATCH",
                        "Access-Control-Allow-Headers": "Content-Type",
                        "Access-Control-Allow-Origin": "*",
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(newOrderList),
                });

                if (!response.ok) {
                    ErrorAlert({ response: response })
                }

                if (response.ok) {
                    window.location.href = '/'
                }

            } catch (error) {
                console.error('Error AddNewOrder: ', error);
            }
        }
    });
}