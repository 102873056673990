import { HOST, registerMerchantApi, registerUserApi } from "../../server/api";
import ErrorAlert from "../other/ErrorAlert";
import { LoginAsMerchant } from "./LoginAsMerchant";

export const RegisterAsMerchant = async ({ email, OTPCode, OTPID, psw, companyName }) => {

  const isMobile = /android/i.test(navigator.userAgent) || /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream
  
  let body = {
    email: email,
    companyName: companyName,
    otp: OTPCode,
    verificationId: OTPID,
    password: psw,
    device: {
      width: JSON.stringify(window.screen.width),
      height: JSON.stringify(window.screen.height),
      cookieEnabled: navigator.cookieEnabled,
      deviceMemory: navigator.deviceMemory,
      appCodeName: navigator.appCodeName,
      appVersion: navigator.appVersion,
      userAgent: navigator.userAgent,
      platform: navigator.platform,
      language: navigator.language,
      vendor: navigator.vendor,
      isMobile: isMobile,
    }
  }

  try {
    const response = await fetch(HOST + registerMerchantApi, {
      method: 'POST',
      headers: {
        "Access-Control-Allow-Methods": "OPTIONS,POST,GET,PATCH",
        "Access-Control-Allow-Headers": "Content-Type",
        "Access-Control-Allow-Origin": "*",
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body),
    });

    if (!response.ok) {
      ErrorAlert({ response: response })
    } else {
      LoginAsMerchant({ email, psw })
    }

  } catch (error) {
    console.error('Error user register:', error);
  }
}