import React, { useContext } from 'react'
import '../../styles/component/secondary/Ads.css'
import { UserContext } from '../../context/UserContext';

export function LineAds() {
  const { userInformation } = useContext(UserContext);

  return !userInformation.isMobile ? (
    <div className='line_ads'>
      <img src='https://s.alicdn.com/@img/imgextra/i3/O1CN01Rn1Jyg1y95G721PM9_!!6000000006535-2-tps-3840-80.png' />
    </div>
  ) : undefined
}

export function RandomSizeAds() {

  const { userInformation } = useContext(UserContext);

  const rectangles = [
    { backgroundColor: 'red', gridColumn: '1 / span 2', gridRow: '1 / span 2' },
    { backgroundColor: 'blue', gridColumn: '3 / span 2', gridRow: '1 / span 1' },
    { backgroundColor: 'green', gridColumn: '3 / span 1', gridRow: '2 / span 2' },
    { backgroundColor: 'yellow', gridColumn: '4 / span 1', gridRow: '2 / span 2' },
    { backgroundColor: 'purple', gridColumn: '1 / span 2', gridRow: '3 / span 1' }
  ];

  return (
    userInformation.isMobile
    ?
    <div>
     {
      rectangles.map((rect, index) => (
        <div style={{width: '98%', borderRadius: 20, marginLeft: '1%', marginTop: 20, marginBottom: 20, height: 160, backgroundColor: rect.backgroundColor }} key={index}>
        </div>
      ))
     } 
    </div>
    :
    <div className="random_size_container">
    {rectangles.map((rect, index) => (
      <div 
        key={index} 
        className="random_size_rectangle" 
        style={{ backgroundColor: rect.backgroundColor, gridColumn: rect.gridColumn, gridRow: rect.gridRow }}>
      </div>
    ))}
  </div>
  )
}