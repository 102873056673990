import { addToBasketApi, HOST, removeToBasketApi } from "../../../server/api";
import ErrorAlert from "../../other/ErrorAlert";

export const ToogleCart = async ({ inBasket, userInformation, singleProduct, setUserInformation }) => {

    if (userInformation.role === 'GUEST') {
        window.localStorage.setItem('temp_redirect', window.location.href)
        window.location.href = '/login'
    } else {
        try {
            const response = await fetch(
                `${HOST}${!inBasket
                    ? addToBasketApi
                    : removeToBasketApi}${!inBasket
                        ? singleProduct.id
                        : '?productIds=' + singleProduct.id}`, {
                method: !inBasket ? 'PUT' : 'DELETE',
                headers: {
                    "Authorization": `Bearer ${userInformation.jwt}`,
                    "Access-Control-Allow-Methods": "OPTIONS,POST,GET,PATCH",
                    "Access-Control-Allow-Headers": "Content-Type",
                    "Access-Control-Allow-Origin": "*",
                    'Content-Type': 'application/json'
                },
            });

            if (!response.ok) {
                ErrorAlert({ response: response })
            } else {
                if (!inBasket) {
                    setUserInformation({ ...userInformation, basket: [...userInformation?.basket, singleProduct?.id] })
                } else {
                    userInformation.basket.forEach(element => {
                        element.id !== singleProduct.id && setUserInformation({ ...userInformation })
                    })
                }
            }

            return response.ok

        } catch (error) {
            console.error('Error AddCart: ', error);
        }
    }

}