import SingleItemContainer from '../../components/client/SingleItemContainer';
import SwiperProductMenu from '../../components/client/SwiperProductMenu';
import NavigationBar from '../../components/client/NavigationBar';
import { ProductContext } from '../../context/ProductContext';
import { SvgMessage, SvgStar } from '../../assets/icons/Svgs';
import { UserContext } from '../../context/UserContext';
import { LineAds } from '../../components/other/Ads';
import Footer from '../../components/client/Footer';
import React, { useContext } from 'react'

import '../../styles/client/screen/SingleProductScreen.css'

export default function SingleProductPage() {
    const { productList } = useContext(ProductContext);
    const { userInformation } = useContext(UserContext);

    return (
        <>
            <LineAds />

            <NavigationBar />

            <SingleItemContainer userInformation={userInformation} productList={productList} />

            {
                productList?.map((p) => (
                    <SwiperProductMenu key={p.categoryId} p={p} />
                ))
            }

            <h2 className='product_comment_text'>Ürün Değerlendirmeleri</h2>

            <div className='product_comment_container'>
                {
                    Array.from({ length: 12 }, (_, key, index) => (
                        <div key={key} className='product_comment'>
                            <div>
                                {
                                    Array.from({ length: 3 }, (_, key, index) => (
                                        <SvgStar key={key} />
                                    ))
                                }

                                <div className='comment_author'>
                                    <p>Ra3ul G.T</p>
                                    <p>|</p>
                                    <p>23 Sentyabr 2024</p>
                                </div>

                                <p>Cok guzel, beyendim</p>
                            </div>


                            <div>
                                <div className='comment_reaction'>
                                    <p><span>Lucary</span>'den alindi</p>

                                    <div className='user_comment_reaction'>
                                        <SvgMessage />
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))
                }
            </div>

            <div className='show_all_comments'>
                <h3>Butun yorumlari goster</h3>
            </div>

            <h2 className='product_comment_text'>Ürün Soru ve Cevapları</h2>

            <div className='product_comment_container'>
                {
                    Array.from({ length: 12 }, (_, key, index) => (
                        <div key={key} className='product_comment'>
                            <div>
                                <p>Rengi ne vaxta solur?</p>

                                <div className='comment_author'>
                                    <p>Ra3ul G.T</p>
                                    <p>|</p>
                                    <p>23 Sentyabr 2024</p>
                                </div>
                            </div>


                            <div>
                                <div className='comment_reaction'>
                                    <p><span>Lucary</span>'nin cavabi</p>
                                </div>

                                <p>Hormetli musteri, eger, uzun muddet gunes altinda qalarsa ve ya uzerine su tokulerse bu halda ola biler.</p>
                            </div>
                        </div>
                    ))
                }
            </div>

            <div className='show_all_comments'>
                <h3>Butun suallari goster</h3>
            </div>

            {
                productList?.map((p) => (
                    <SwiperProductMenu key={p.categoryId} p={p} />
                ))
            }

            <Footer />
        </>
    )
}
