import React from 'react'
import '../../styles/client/component/PopularMerchants.css'

export default function PopularMerchants({ p, key }) {
    return (
        <div className='item_list' key={key}>
            <div className='home_category'>
                <h2 className='category_name'>Populyar Mağazalar</h2>
                <div className='popular_merchants_container'>
                    <img src="https://images.unsplash.com/photo-1622964318124-d87cb88d24e2?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTJ8fGxvZ298ZW58MHx8MHx8fDA%3D" />
                    <p>Lucary Shop</p>
                </div>
            </div>
        </div>
    )
}
