import React, { useEffect, useState } from 'react'
import { addNewCategoryList, getActiveCategoryList, HOST } from '../../server/api';
import ErrorAlert from '../../functions/other/ErrorAlert';

export default function NewCategories({ userInformation }) {

    const [categoryList, setCategoryList] = useState([])

    useEffect(() => {
        (async () => {

            const response = await fetch(`${HOST}${getActiveCategoryList}`, {
                method: 'GET',
                headers: {
                    "Access-Control-Allow-Methods": "OPTIONS,POST,GET,PATCH",
                    "Access-Control-Allow-Headers": "Content-Type",
                    "Access-Control-Allow-Origin": "*",
                    'Content-Type': 'application/json'
                }
            });


            if (!response.ok) {
                ErrorAlert({ response: response })
            }

            setCategoryList(await response.json())

        })()
    }, [])



    return (
        <div>
            <form onSubmit={async e => {
                e.preventDefault()

                await fetch(`${HOST}${addNewCategoryList}${e.target[0].value}`, {
                    method: 'PUT',
                    headers: {
                        "Authorization": `Bearer ${userInformation.jwt}`,
                        "Access-Control-Allow-Methods": "OPTIONS,POST,GET,PATCH",
                        "Access-Control-Allow-Headers": "Content-Type",
                        "Access-Control-Allow-Origin": "*",
                        'Content-Type': 'application/json'
                    }
                }).then(() => {
                    window.location.reload()
                })

            }}>
                <input placeholder='Kategori adi ..' />
            </form>

            <ul>
                {
                    categoryList.map((c, key) => (
                        <li key={key}>{c.name}</li>
                    ))
                }
            </ul>
        </div>
    )
}
