import axios from "axios";
import { createContext, useState, useEffect } from "react";
import { GetUserInformation } from "../functions/client/GetUserInformation";
import { GetMerchantData } from "../functions/merchant/GetMerchantData";
import { CreateUserAsGuest } from "../functions/client/CreateUserAsGuest";
import { setDeviceId } from "../functions/other/SetCookie";
import { GetUserBasket } from "../functions/client/Get/GetUserBasket";

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
    const [userInformation, setUserInformation] = useState(undefined);

    let token = localStorage.getItem('lucary-token')
    let role = localStorage.getItem('role')

    const getAllUserInformation = async () => {
        const isMobile = /android/i.test(navigator.userAgent) || /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream

        const defaultUserInformation = {
            cookieEnabled: navigator.cookieEnabled,
            deviceMemory: navigator.deviceMemory,
            appCodeName: navigator.appCodeName,
            appVersion: navigator.appVersion,
            userAgent: navigator.userAgent,
            platform: navigator.platform,
            language: navigator.language,
            vendor: navigator.vendor,
            role: 'GUEST',
            isMobile: isMobile,
            width: JSON.stringify(window.screen.width),
            height: JSON.stringify(window.screen.height),
        }

        if (token === 'undefined') {
            window.localStorage.clear()
            window.location.reload()
        }

        switch (role) {
            case 'MERCHANT':
                GetMerchantData({ jwt: token, isMobile })
                    .then(response => {
                        setUserInformation({
                            ...response,
                            jwt: token
                        })
                    })
                break;
            case 'USER':
                GetUserInformation({ jwt: token, isMobile, setUserInformation })
                break;
            case 'GUEST':
                GetUserInformation({ jwt: token, isMobile, defaultUserInformation, setUserInformation })
                break;
            case 'ADMIN':
                setUserInformation({
                    role: role,
                    jwt: token
                })
                break;
            default:
                CreateUserAsGuest()
                    .then(response => {

                        setUserInformation({
                            ...defaultUserInformation,
                            role: response.role,
                            jwt: response.jwt
                        })

                        localStorage.setItem('lucary-token', response.jwt)
                        localStorage.setItem('role', response.role)
                    })
                break;
        }
    };

    useEffect(() => {
        getAllUserInformation();
    }, [role]);

    return (
        <UserContext.Provider value={{ userInformation, setUserInformation }}>
            {children}
        </UserContext.Provider>
    );
};
