import { getRenewToken, HOST } from "../../server/api";
import ErrorAlert from "./ErrorAlert";
import Cookies from 'js-cookie';

export const RenewToken = async ({ jwt, refreshToken }) => {
    try {
        const response = await fetch(`${HOST}${getRenewToken}?token=${jwt}&refreshToken=${refreshToken}`, {
            method: 'PUT',
            headers: {
                "Access-Control-Allow-Methods": "OPTIONS,POST,GET,PATCH",
                "Access-Control-Allow-Headers": "Content-Type",
                "Access-Control-Allow-Origin": "*",
                'Content-Type': 'application/json'
            }
        });
        

        if (!response.ok) {
            ErrorAlert({ response: response })
        }

        const data = await response.json();

        window.localStorage.setItem('lucary-token', data.jwt)
        window.localStorage.setItem('role', data.role)
        
        Cookies.set('refreshToken', data.refreshToken)

        window.location.reload()
    } catch (error) {
        console.error('Error get otp id:', error);
    }
}