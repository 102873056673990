import React, { useEffect } from 'react'
import '../../styles/component/secondary/AlertPopUp.css'
import { SvgReject } from '../../assets/icons/Svgs';

export default function AlertPopUp({ alertPopUp, setAlertPopUp }) {
    return alertPopUp[1] ? (
        <div className={alertPopUp[1] ? 'alert_popup' : 'alert_popup invisible'}>
            <div onClick={() => setAlertPopUp(['', false])}><SvgReject /></div>
            <p>{alertPopUp[0]}</p>
        </div>
    ) : null
}
