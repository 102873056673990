import React from 'react'
import { SvgCart, SvgHome, SvgProfileLogin } from '../../assets/icons/Svgs'

export default function MobileNaviMenu() {
    return (
        <div className='mobil_navigation_controller'>
            <div className='__active'>
                <SvgHome />
                <p>Home</p>
            </div>
            <div>
                <SvgCart c={'#1C274C'} />
                <p>Sebet</p>
            </div>
            <div onClick={() => {
                if (window.localStorage.getItem('role') === 'USER') {
                    window.location.href = '/profile?user-information'
                } else {
                    window.location.href = '/login'
                }
            }}>
                <SvgProfileLogin />
                <p>Profile</p>
            </div>
            <div>
                <SvgProfileLogin />
                <p>Social</p>
            </div>
        </div>
    )
}
