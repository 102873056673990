import React, { useState } from 'react'
import '../../styles/merchant/screen/RegisterAndLoginScreen.css'
import { SvgAccept, SvgEyeClose, SvgEyeOpen, SvgReject } from '../../assets/icons/Svgs';
import { SendOTP } from '../../functions/other/SendOtpCode';
import { RegisterAsMerchant } from '../../functions/merchant/RegisterAsMerchant';
import NavigationBar from '../../components/client/NavigationBar';
import { CheckEmail } from '../../functions/other/CheckEmail';
import { ToastContainer } from 'react-toastify';

export default function MerchantRegisterPage() {

  let [email, setEmail] = useState('');
  let [psw, setPsw] = useState('');
  let [companyName, setCompanyName] = useState('');
  let [OTPID, setOTPID] = useState('');
  let [OTPCode, setOTPCode] = useState('');
  let [visiblePsw, setVisiblePsw] = useState(false);
  let [emailCheckLoader, setEmailCheckLoader] = useState(false);
  let [isEmailValid, setIsEmailValid] = useState(false);
  let [pswRegex, setPswRegex] = useState([false, false, false, false, false]);

  return (
    <>
      <NavigationBar hideSearch={true} />
      <ToastContainer />

      <div className='registerAndLoginScreenForm'>

        {

          OTPID.length < 1
            ?

            <div className='registration_form'>
              <div className='reg_sec_visible'>
                <p>E-mail</p>
                <div className='input_end_icon'>
                  {emailCheckLoader ?
                    <img alt='spinner' className='loading_spinner' src={require('../../assets/animation/loading_spinner.gif')} />
                    :
                    <div className='email_reject'>
                      {isEmailValid ? <SvgAccept /> : <SvgReject />}
                      {/* {
                        !isEmailValid &&
                        <p>Bu e-post adresi ilə artıq qeydiyyatdan keçilib</p>
                      } */}
                    </div>
                  }
                </div>
                <input onBlur={() => CheckEmail({ email, setEmailCheckLoader, setIsEmailValid, isEmailValid })} onChange={(e) => {
                  setEmail(e.target.value)
                  setIsEmailValid(false)
                }} id='email' value={email} type='email' />
              </div>

              <div className='reg_sec_visible'>
                <p>Şirkət Adı</p>
                <input onChange={(e) => setCompanyName(e.target.value)} id='companyName' value={companyName} type='text' />
              </div>

              <div className='reg_sec_visible'>
                <p>Şifrə təyin edin</p>
                <div className='input_end_icon' onClick={() => setVisiblePsw(!visiblePsw)}>
                  {
                    !visiblePsw ? <SvgEyeClose /> : <SvgEyeOpen />
                  }
                </div>
                <input onChange={(e) => {
                  setPsw(e.target.value)

                  setPswRegex([
                    e.target.value.length >= 8 ? true : false,
                    /[a-z]/.test(e.target.value) ? true : false,
                    /[A-Z]/.test(e.target.value) ? true : false,
                    /\d/.test(e.target.value) ? true : false,
                    /[@$!%*?&._]/.test(e.target.value) ? true : false
                  ]);
                }} id='psw' value={psw} type={visiblePsw ? 'text' : 'password'} />
              </div>

              <div className='psw_regex_info'>
                <p className={pswRegex[0] ? 'a' : 'b'}>Minimum 8 simvol olmalıdır</p>
                <p className={pswRegex[1] ? 'a' : 'b'}>Ən az bir kiçik hərf olmalıdır</p>
                <p className={pswRegex[2] ? 'a' : 'b'}>Ən az bir böyük hərf olmalıdır</p>
                <p className={pswRegex[3] ? 'a' : 'b'}>Ən az bir rəqəm hərf olmalıdır</p>
                <p className={pswRegex[4] ? 'a' : 'b'}>@ $ ! % * ? & . _ Bu simvollardan ən az biri olmalıdır</p>
              </div>

              <div style={{ opacity: isEmailValid ? 1 : 0.4 }} className='__continue' onClick={() => SendOTP({ email, isEmailValid, setOTPID, isPasswordValid: !pswRegex.includes(false) })}>
                <h3>Davam Et</h3>
              </div>

              <p className='__accept'>Siz davam edərkən, Lucary'nin <span>İstifadəçi sövdələşməsini</span> və <span>gizlilik şərtlərini</span> qəbul etmiş olursunuz.</p>

              <div style={{ display: 'flex' }}>
                <p style={{ marginLeft: 8, marginRight: 8, fontSize: 12, marginTop: 18, fontStyle: 'italic' }}>Kömək lazımdır?</p>
              </div>

              <div className='__already'>
                <p>Bir hesabınız var?</p>
                <h3 onClick={() => window.location.href = '/login'}>Daxil Ol</h3>
              </div>
            </div>
            :
            <div className='registration_form'>
              <div className='reg_sec_visible'>
                <p>OTP kodu daxil edin</p>
                <input onChange={(e) => setOTPCode(e.target.value)} id='OTPCode' value={OTPCode} />
              </div>

              <div className='__continue' onClick={() => RegisterAsMerchant({ companyName, email, OTPCode, OTPID, psw })}>
                <h3>Kodu təstiqlə</h3>
              </div>

              <p className='__accept'>E-Mail adresinizə təstiq kodu göndərildi.</p>

              <div style={{ display: 'flex' }}>
                <p style={{ marginLeft: 8, marginRight: 8, fontSize: 12, marginTop: 18, fontStyle: 'italic' }}>Kömək lazımdır?</p>
              </div>

              <div className='__already'>
                <p>Bir hesabınız var?</p>
                <h3 onClick={() => window.location.href = '/login'}>Daxil Ol</h3>
              </div>
            </div>
        }
      </div>
    </>
  )
}
