import { createOTPApi, HOST } from "../../server/api";
import ErrorAlert from "./ErrorAlert";

export const SendOTP = async ({ isEmailValid, setOTPID, email, isPasswordValid }) => {
    if (isEmailValid && isPasswordValid) {
        try {
            const response = await fetch(HOST + createOTPApi + email, {
                method: 'POST',
                headers: {
                    "Access-Control-Allow-Methods": "OPTIONS,POST,GET,PATCH",
                    "Access-Control-Allow-Headers": "Content-Type",
                    "Access-Control-Allow-Origin": "*",
                    'Content-Type': 'application/json'
                }
            });

            if (!response.ok) {
                ErrorAlert({ response: response })
            }

            const data = await response.text();
            setOTPID(data)
        } catch (error) {
            console.error('Error get otp id:', error);
        }
    }
}