import MerchantAddProductScreen from '../../screens/merchant/MerchantAddProductScreen';
import MerchantProductScreen from '../../screens/merchant/MerchantProductScreen';
import { UserContext } from '../../context/UserContext';
import LeftBar from '../../components/merchant/LeftBar';
import Header from '../../components/merchant/Header';
import React, { useContext, useEffect } from 'react';

import '../../styles/merchant/screen/MerchantDashboardScreen.css'
import { ToastContainer } from 'react-toastify';

export default function MerchantHomePage() {

    const { userInformation } = useContext(UserContext);

    useEffect(() => {
        console.log(userInformation);

        if (window.localStorage.getItem('role') !== 'MERCHANT') {
            window.location.href = 'login'
        }

    }, [userInformation.role]);

    return userInformation.role !== 'MERCHANT' ?
        <div style={{
            width: '100vw',
            height: '100vh',
            display: 'flex',
            alignItems: 'center',
            backgroundColor: '#3EAFE4',
            justifyContent: 'center',
        }}>
            <ToastContainer />
            <img style={{ width: 100, height: 100, objectFit: 'cover' }} src={require('../../assets/gifs/loading.gif')} />
        </div>
        : (
            <>
                <ToastContainer />
                <Header userInformation={userInformation} path={window.location.pathname} />
                <div className='merchant_dashboard_screen_container'>
                    <LeftBar />
                    {window.location.pathname === '/merchat-products' && <MerchantProductScreen userInformation={userInformation} />}
                    {window.location.pathname === '/merchat-add-product' && <MerchantAddProductScreen userInformation={userInformation} />}
                </div>
            </>
        )
}
