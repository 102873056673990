import { HOST, loginApi } from "../../server/api";
import Cookies from 'js-cookie';
import ErrorAlert from "../other/ErrorAlert";
import { setDeviceId, setRefreshToken } from "../other/SetCookie";

export const LoginAsMerchant = async ({ email, psw }) => {
  const isMobile = /android/i.test(navigator.userAgent) || /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream

  let body = {
    usernameOrEmailOrPhoneNumber: email,
    password: psw,
    deviceRequest: {
      deviceId: Cookies.get('deviceId') !== undefined ? Cookies.get('deviceId') : '6',
      width: JSON.stringify(window.screen.width),
      height: JSON.stringify(window.screen.height),
      cookieEnabled: navigator.cookieEnabled,
      deviceMemory: navigator.deviceMemory,
      appCodeName: navigator.appCodeName,
      appVersion: navigator.appVersion,
      userAgent: navigator.userAgent,
      platform: navigator.platform,
      language: navigator.language,
      vendor: navigator.vendor,
      isMobile: isMobile,
    }
  }

  try {
    const response = await fetch(HOST + loginApi, {
      method: 'POST',
      headers: {
        "Access-Control-Allow-Methods": "OPTIONS,POST,GET,PATCH",
        "Access-Control-Allow-Headers": "Content-Type",
        "Access-Control-Allow-Origin": "*",
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body),
    });

    if (!response.ok) {
      ErrorAlert({ response: response })
    } else {
      let data = await response.json()

      let jwt = data.jwt
      let role = data.role

      if (role !== 'MERCHANT') {
        ErrorAlert({ code: '666' })
      } else {
        
        setRefreshToken({refreshToken: data.refreshToken})
        setDeviceId({deviceId: data.deviceId})

        window.localStorage.setItem("lucary-token", jwt)
        window.localStorage.setItem("role", role)
        window.location.href = '/'
      }

    }

  } catch (error) {
    console.error('Error user register:', error);
  }
}