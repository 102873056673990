import { GoogleAuthProvider } from "firebase/auth";
import { initializeApp } from "firebase/app";
import { getFirestore } from "@firebase/firestore"
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyCUIhKBGRJYPE1MfD0M6alCG1-z8EpY2O0",
  authDomain: "lucary-test-323fc.firebaseapp.com",
  projectId: "lucary-test-323fc",
  storageBucket: "lucary-test-323fc.appspot.com",
  messagingSenderId: "25131429256",
  appId: "1:25131429256:web:e6448f48b806e5a25cde3d"
};

const app = initializeApp(firebaseConfig);
const firestore = getFirestore(app)
const db = getFirestore(app);
const provider = new GoogleAuthProvider();
const storage = getStorage(app);

export { db, storage, firestore, provider }