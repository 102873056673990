import React from 'react'
import Product from './Product'

export default function SwiperProductMenu({ p, userInformation }) {
    return (
        <div className='item_list'>
            <div className='home_category'>
                <h2 className='category_name'>{p?.categoryName}</h2>
                <div className='products'>
                    {
                        p?.products?.map((i) => (
                            <Product userInformation={userInformation} key={i?.id} p={p} i={i} />
                        ))
                    }
                </div>
            </div>
        </div>
    )
}
