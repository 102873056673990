import React, { useContext, useEffect } from 'react'
import { UserContext } from '../../../context/UserContext';
import { getUserOrderHistoryListApi, HOST } from '../../../server/api';
import { ToastContainer } from 'react-toastify';
import ErrorAlert from '../../../functions/other/ErrorAlert';

export default function UserOrderHistory() {

    const { userInformation } = useContext(UserContext);


    useEffect(() => {
        (async () => {
            try {
                const response = await fetch(HOST + getUserOrderHistoryListApi, {
                    method: 'GET',
                    headers: {
                        "Authorization": `Bearer ${userInformation?.jwt}`,
                        "Access-Control-Allow-Methods": "OPTIONS,POST,GET,PATCH",
                        "Access-Control-Allow-Headers": "Content-Type",
                        "Access-Control-Allow-Origin": "*",
                        'Content-Type': 'application/json'
                    }
                });

                if (!response.ok) {
                    ErrorAlert({ response: response })
                } else {
                    console.log(response);
                }
            } catch (error) {
                console.log('All User Order History Error: ', error);
            }
        })()
    }, [])



    return (
        <>
            <ToastContainer />

            <div className="order-history">
                <div className="order-card">
                    <div className="card-header">
                        <div className="product-image">
                            <img src="urun_resmi_url" alt="Ürün Resmi" />
                        </div>
                        <div className="order-summary">
                            <h3 className="product-name">Ürün İsmi</h3>
                            <p className="product-price">Fiyat: 250 TL</p>
                            <p className="order-status preparing">Durum: Hazırlanıyor</p>
                        </div>
                    </div>
                    <div className="card-body">
                        <p className="order-number">Sipariş Numarası: #123456</p>
                        <p className="product-quantity">Adet: 2</p>
                        <p className="product-category">Kategori: Elektronik</p>
                        <p className="seller-info">Satıcı: ABC Teknoloji</p>
                    </div>
                    <div className="card-footer">
                        <button className="review-order-btn">Siparişi Değerlendir</button>
                    </div>
                </div>
            </div>
            <div className="order-history">
                <div className="order-card">
                    <div className="card-header">
                        <div className="product-image">
                            <img src="urun_resmi_url" alt="Ürün Resmi" />
                        </div>
                        <div className="order-summary">
                            <h3 className="product-name">Ürün İsmi</h3>
                            <p className="product-price">Fiyat: 250 TL</p>
                            <p className="order-status preparing">Durum: Hazırlanıyor</p>
                        </div>
                    </div>
                    <div className="card-body">
                        <p className="order-number">Sipariş Numarası: #123456</p>
                        <p className="product-quantity">Adet: 2</p>
                        <p className="product-category">Kategori: Elektronik</p>
                        <p className="seller-info">Satıcı: ABC Teknoloji</p>
                    </div>
                    <div className="card-footer">
                        <button className="review-order-btn">Siparişi Değerlendir</button>
                    </div>
                </div>
            </div>
            <div className="order-history">
                <div className="order-card">
                    <div className="card-header">
                        <div className="product-image">
                            <img src="urun_resmi_url" alt="Ürün Resmi" />
                        </div>
                        <div className="order-summary">
                            <h3 className="product-name">Ürün İsmi</h3>
                            <p className="product-price">Fiyat: 250 TL</p>
                            <p className="order-status preparing">Durum: Hazırlanıyor</p>
                        </div>
                    </div>
                    <div className="card-body">
                        <p className="order-number">Sipariş Numarası: #123456</p>
                        <p className="product-quantity">Adet: 2</p>
                        <p className="product-category">Kategori: Elektronik</p>
                        <p className="seller-info">Satıcı: ABC Teknoloji</p>
                    </div>
                    <div className="card-footer">
                        <button className="review-order-btn">Siparişi Değerlendir</button>
                    </div>
                </div>
            </div>
        </>
    )
}
