import { Flip, toast } from 'react-toastify';
import Cookies from 'js-cookie';
import { messages } from '../../Localization/Localization';
import { RenewToken } from './RenewToken';
import { merchant } from '../../server/api';

export default async function ErrorAlert({ response, jwt }) {
    console.log('Error Alert');

    let toastAnime = { position: "bottom-center", autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "light", transition: Flip, }

    try {
        let data = await response.json()
        console.log('Error Alert data: ', data);

        if (data.code !== undefined) {
            switch (data.code) {
                case '001': RenewToken({ jwt: jwt, refreshToken: Cookies.get('refreshToken') }); break;
                case '002': toast.info(messages.register_form_is_not_valid[0], toastAnime); break;
                case '004': toast.info(messages.email_or_psw_incorrect[0], toastAnime); break;
                case '005': toast.info(messages.access_denied[0], toastAnime); break;
                default:
                    if (data.message !== 'Access Denied') {
                        window.localStorage.clear()
                        window.location.reload()
                    }
                    toast.info(data.message, toastAnime); break;
            }
        } else {
            toast.info('Ops! Gozlenilmeyen xeta bas verdi', toastAnime)
        }
    } catch (error) {
        console.log('Error Alert custom case');
        
        switch (response.code) {
            case '666':
                toast.info(messages.loged_in_merchant[0], toastAnime)

                setTimeout(() => {
                    window.location.href = merchant
                }, 1000);
                break;
            case 'x066':
                window.localStorage.clear()
                window.location.reload()
                break;
            default: toast.info(messages.register_form_is_not_valid[0], toastAnime); break;
        }

    }

}