import { ToogleFavList } from '../../functions/client/Toogle/ToogleFavList';
import { SvgHeart} from '../../assets/icons/Svgs'
import React from 'react'

import '../../styles/client/component/Product.css'

export default function Product({ userInformation, p, i }) {
    return (
        <>
            <div className="product-card">
                <div className="product-image-container">
                    <img onClick={() => window.location.href = `/product-detail?category=${p?.categoryId}&item=${i?.id}`} src={i?.imgUrl[0]} alt="Product Image" className="product-image" />
                    <div id={i.id} onClick={() => ToogleFavList({ singleProduct: i, userInformation: userInformation }).then(response => response && document.querySelector(`#${i.id}`).classList.toggle('_active'))} className={userInformation?.myFavProducts?.includes(i?.id) ? "_active favorite-btn" : "favorite-btn"}><SvgHeart /></div>
                </div>
                <div className="product-info">
                    <h3 className="product-title">{i?.title}</h3>
                    <p className="product-price">
                        <span className="discounted-price">{i?.price} AZN</span>
                        {/* <span className="old-price">12 AZN</span> */}
                    </p>
                    <div className="product-rating">
                        {
                            Array.from({ length: i?.rating }, (_, key, index) => (
                                <span key={key} className="star">★</span>
                            ))
                        }
                        {
                            Array.from({ length: 5 - i?.rating }, (_, key, index) => (
                                <span key={key} className="star">☆</span>
                            ))
                        }
                    </div>
                </div>
            </div>
        </>
    )
}
