import React from 'react'
import { SvgCart, SvgLogOut, SvgProfileLogin } from '../../assets/icons/Svgs'
import { LogOut } from '../../functions/client/LogOut'

export default function WebNaviMenu({ userInformation }) {
    return (
        <div className='header_top_navi'>
            <div className='login_btn' onClick={() => window.location.href = '/basket'}>
                <SvgCart c="#000" />
                {userInformation?.basket?.length > 0 && <div className='profile_red_dot'></div>}
            </div>
            <div className='login_btn' onClick={() => window.location.href = '/profile?user-information'}>
                <SvgProfileLogin stroke="#000" />
                {userInformation?.username.includes('user-') && <div className='profile_red_dot'></div>}
            </div>
            <div onClick={() => LogOut()} className='login_btn'>
                <SvgLogOut c="#000" />
            </div>
        </div>
    )
}