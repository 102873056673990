import React, { useContext, useEffect, useState } from 'react'
import { LineAds } from '../../components/other/Ads'
import NavigationBar from '../../components/client/NavigationBar'
import SwiperProductMenu from '../../components/client/SwiperProductMenu'
import { ProductContext } from '../../context/ProductContext';
import { UserContext } from '../../context/UserContext';
import '../../styles/client/page/BasketPage.css'
import { SvgBin } from '../../assets/icons/Svgs';
import { GetProductById } from '../../functions/client/GetProductList';
import Footer from '../../components/client/Footer';
import { ChaneCountAtBasket } from '../../functions/client/Toogle/ChaneCountAtBasket';

export default function BasketPage() {

  const { productList } = useContext(ProductContext);
  const { userInformation, setUserInformation } = useContext(UserContext);

  const [userCurrentBasket, setUserCurrentBasket] = useState([])
  let tempUserCurrentBasketIds = []

  useEffect(() => {
    userInformation?.basket?.forEach(itemID => {
      GetProductById({ itemID, jwt: userInformation.jwt })
        .then(response => {
          if (!tempUserCurrentBasketIds.includes(response.id)) {
            tempUserCurrentBasketIds.push(response.id)
            setUserCurrentBasket(userCurrentBasket => [...userCurrentBasket, response])
          }
        })
    });
  }, [userInformation])


  return (
    <>
      <LineAds />

      <NavigationBar />

      <div className='basket'>
        <div className='basket_product_side'>

          <div className="confirm-basket">
            {/* <div className="basket-info">
              <span className="total-amount">Toplam: 450 AZN</span>
              <span className="total-discount">Toplam Endirim: 50 AZN</span>
            </div> */}
            <div className="confirm-button">
              <button onClick={() => window.location.href = 'confirm-order'}>Sifarisi Tamamla</button>
            </div>
          </div>

          {
            userCurrentBasket.map((item, key) => (
              <div className="basket-item" key={key}>
                <div className="item-image">
                  <img src={item?.imgUrl[0]} alt={item?.title} />
                </div>
                <div className="item-info">
                  <h3 className="item-name">{item?.title}</h3>
                  <p className="item-price">{item?.price} AZN</p>
                  <div className="item-quantity">
                    <button onClick={() => ChaneCountAtBasket('minus', item, setUserCurrentBasket, userCurrentBasket, userInformation, setUserInformation)} className="btn decrease-btn">-</button>
                    <span className="quantity">{item.count !== undefined ? item.count : 1}</span>
                    <button onClick={() => ChaneCountAtBasket('plus', item, setUserCurrentBasket, userCurrentBasket, userInformation, setUserInformation)} className="btn increase-btn">+</button>
                  </div>
                </div>
                <button onClick={() => ChaneCountAtBasket('remove', item, setUserCurrentBasket, userCurrentBasket, userInformation, setUserInformation)} className="remove-btn">✖</button>
              </div>
            ))
          }
        </div>

      </div>



      {
        productList?.map((p) => (
          <SwiperProductMenu key={p.categoryId} p={p} />
        ))
      }

      <Footer />
    </>
  )
}
