// !OTHER
// export const HOST = 'https://lucary-store-service-drq7fqfn5a-ey.a.run.app/'
export const HOST = 'https://lucary-store-service-833714456452.europe-west3.run.app/'
export const HOSTPRODUCT = 'https://lucary-store-service-drq7fqfn5a-ey.a.run.app/'
export const createOTPApi = 'verifications/create-otp/'

// !USER
export const checkEmailApi = 'accounts/is-email-exist/'
export const registerUserApi = 'users/register'
export const loginApi = 'auth/login'
export const userInformation = 'users/current'
export const userBasket = 'baskets/all-product-ids'
export const getRenewToken = 'auth/renew-token'
export const getUserFavListApi = 'users/my-favs'
export const getUserOrderHistoryListApi = 'orders/all-user-orders'

export const registerGuestUserApi = 'guests/register'

// !ORDER
export const addToBasketApi = 'baskets/add-product/'
export const removeToBasketApi = 'baskets/remove-products'
export const addNewOrderApi = 'orders/create'

// !MERCHANT
export const merchant = 'https://merchant.lucary.com/'
export const registerMerchantApi = 'merchants/register'
export const getMerchantDataApi = 'merchants/current'
export const getMerchantPendingRequestsApi = 'requests/all-pending-requests'

// !PRODUCTS
export const getMerchantProductsApi = 'products/all-by-merchant'
export const saveNewProductApi = 'requests/save'
export const getAllRequestsProductApi = 'requests/all-request'
export const getPendingRequestsProductApi = 'requests/all-pending-request'
export const getAllProductApi = 'products/all'
export const getProductByIdApi = 'products/by-id-user/'
export const addFavProductApi = 'products/add-to-fav/'

export const putRequestsAcceptProductApi = 'requests/accept-request'
export const putRequestsRejectProductApi = 'requests/reject-request'
export const getActiveCategoryList = 'categories/all'
export const addNewCategoryList = 'categories/save/'
export const getAllMerchantList = 'companies/all'
export const addCategoryToMerchant = 'merchants/add-category'