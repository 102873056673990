export const messages = {
    product_request_accepted: [
        ' nömrəli paylasiminiz qebul edildi'
    ],
    email_or_psw_incorrect: [
        'E-mail və ya şifrə yanlışdır!'
    ],
    register_form_is_not_valid: [
        'Ops! Daxil etdiyin melumatlarda sehvlik var!'
    ],
    loged_in_merchant: [
        'Merchant hesabina yonlendirildiniz!'
    ],
    access_denied: [
        'Bu sorgu ucun selahiyetiniz yoxdur!'
    ],
}